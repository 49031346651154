import React from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Footer, Navbar } from "../components";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <div>
        {/* Введение */}
        <section className="contact-intro py-5 text-center bg-light">
          <Container>
            <h1 className="display-4">Contact Us</h1>
            <p className="lead">
              We would love to hear from you! Whether you have a question,
              feedback, or a partnership proposal, feel free to reach out to us.
              Our team is always here to assist you.
            </p>
          </Container>
        </section>

        {/* Форма для связи */}
        <section className="contact-form py-5">
          <Container>
            <h2 className="text-center mb-4">Get In Touch</h2>
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name" />
                  </Form.Group>

                  <Form.Group controlId="formEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email" />
                  </Form.Group>

                  <Form.Group controlId="formMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Your message"
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Send Message
                  </Button>
                </Form>
              </Col>

              <Col md={6}>
                <Card className="shadow-sm">
                  <Card.Body>
                    <Card.Title>Our Location</Card.Title>
                    <Card.Text>
                      We are located in the heart of the city. Our office is
                      easily accessible by public transport.
                    </Card.Text>
                    <p>
                      <strong>Address:</strong> 122 Main Street, Miami, USA
                    </p>
                    <p>
                      <strong>Phone:</strong> +1 (325) 785 21 21
                    </p>
                    <p>
                      <strong>Email:</strong> info@travellparke.com
                    </p>
                    <p>
                      <strong>Business Hours:</strong> Monday to Friday, 9 AM -
                      6 PM
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Дополнительные способы связи */}
        <section className="other-ways-to-reach py-5 bg-light">
          <Container>
            <h2 className="text-center mb-4">Other Ways to Reach Us</h2>
            <Row>
              <Col md={4}>
                <Card className="shadow-sm text-center">
                  <Card.Body>
                    <Card.Title>Follow Us on Social Media</Card.Title>
                    <Card.Text>
                      Stay updated with the latest news and offers by following
                      us on social media.
                    </Card.Text>
                    <ul className="list-unstyled">
                      <li>
                        <Link to="#" className="text-primary">
                          Facebook
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="text-info">
                          Twitter
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="text-danger">
                          Instagram
                        </Link>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="shadow-sm text-center">
                  <Card.Body>
                    <Card.Title>Live Chat</Card.Title>
                    <Card.Text>
                      Our support team is available 24/7 via live chat on our
                      website. Click the chat icon on the bottom right corner.
                    </Card.Text>
                    <Button
                      variant="outline-primary"
                      size="lg"
                      className="w-100"
                    >
                      Start Chat
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="shadow-sm text-center">
                  <Card.Body>
                    <Card.Title>Request a Call</Card.Title>
                    <Card.Text>
                      If you prefer to talk over the phone, simply request a
                      call from one of our team members.
                    </Card.Text>
                    <Button
                      variant="outline-success"
                      size="lg"
                      className="w-100"
                    >
                      Request Call
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Местоположение на карте */}
        <section className="location-map py-5">
          <Container>
            <h2 className="text-center mb-4">Find Us On The Map</h2>
            <Row>
              <Col md={12}>
                <div className="map-container" style={{ height: "400px" }}>
                  {/* Вставьте карту, например, с помощью Google Maps */}
                  <iframe
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    src="https://www.google.com/maps/embed?pb=...your-map-link..."
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Призыв к действию */}
        <section className="cta-section py-5 bg-dark text-white text-center">
          <Container>
            <h2 className="mb-4">Let's Work Together</h2>
            <p className="lead">
              We're always ready to assist you with your travel needs. Get in
              touch today, and let's start working on your next project
              together!
            </p>
            <Link to="/contact">
              <Button variant="light" size="lg">
                Contact Us Now
              </Button>
            </Link>
          </Container>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;

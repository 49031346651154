import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const Advantages = () => {
  return (
    <section className="advantages-section py-5">
      <Container>
        <h2 className="display-4 text-center mb-4">Why Choose travellparke</h2>
        <p className="lead text-center mb-5">
          Discover the key reasons why travellparke is the preferred partner for
          businesses seeking streamlined, reliable, and cost-effective travel
          solutions.
        </p>
        <Row>
          <Col md={3} sm={6} className="mb-4">
            <Card className="shadow-sm text-center h-100">
              <Card.Body>
                <h5 className="card-title text-primary">
                  10+ Years of Excellence
                </h5>
                <p className="card-text">
                  With over a decade of experience, we’ve perfected corporate
                  travel management, delivering a seamless experience to our
                  clients worldwide.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={6} className="mb-4">
            <Card className="shadow-sm text-center h-100">
              <Card.Body>
                <h5 className="card-title text-primary">
                  Trusted by Over 10,000 Clients
                </h5>
                <p className="card-text">
                  Each year, thousands of businesses place their trust in
                  travellparke for worry-free travel solutions tailored to their
                  specific needs.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={6} className="mb-4">
            <Card className="shadow-sm text-center h-100">
              <Card.Body>
                <h5 className="card-title text-primary">
                  Exclusive Travel Offers
                </h5>
                <p className="card-text">
                  Our strong relationships with industry-leading providers mean
                  that our clients get access to exclusive rates and benefits,
                  ensuring maximum value.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} sm={6} className="mb-4">
            <Card className="shadow-sm text-center h-100">
              <Card.Body>
                <h5 className="card-title text-primary">
                  Global Network & Coverage
                </h5>
                <p className="card-text">
                  With a network of trusted partners across the globe,
                  travellparke ensures that your business travels are smooth and
                  efficient, no matter where you are.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Advantages;

import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Button, Carousel } from "react-bootstrap";
import { Footer, Navbar } from "../components";

const Home = () => {
  return (
    <>
      <Navbar />
      <div>
        {/* Большой баннер с кнопками */}
        <div className="banner bg-dark text-white text-center py-5">
          <Container>
            <h1 className="display-4">Welcome to travellparke</h1>
            <p>
              Your B2B travel solution. Connect with partners, book flights and
              hotels, all in one platform.
            </p>
            <Link to="/contact">
              <Button variant="success" size="lg" className="mt-3">
                Contact Us
              </Button>
            </Link>
          </Container>
        </div>

        {/* О нас */}
        <Container className="py-5">
          <Row>
            <Col md={6}>
              <h2>About Us</h2>
              <p>
                At travellparke, we specialize in providing a seamless B2B travel
                platform that connects agents with reliable travel suppliers.
                Our service includes flight booking, hotel reservations, and
                comprehensive partner solutions.
              </p>
            </Col>
            <Col md={6}>
              <img
                src="/images/about.avif"
                alt="About Us"
                className="img-fluid rounded"
              />
            </Col>
          </Row>
        </Container>

        {/* Преимущества */}
        <Container className="bg-light py-5">
          <h2 className="text-center">Our Advantages</h2>
          <Row>
            <Col md={4}>
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>Reliable Partners</Card.Title>
                  <p>
                    Work with top-tier travel suppliers to ensure quality and
                    reliability.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>Comprehensive Solutions</Card.Title>
                  <p>Book flights, hotels, and more, all from one platform.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>Support 24/7</Card.Title>
                  <p>
                    Our support team is available round the clock to assist you.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Баннер с кнопкой */}
        <div className="banner bg-primary text-white text-center py-4">
          <Container>
            <p>
              Join travellparke today and boost your business with seamless
              travel solutions.
            </p>
            <Link to="/contact">
              <Button variant="light" size="lg">
                Get in Touch
              </Button>
            </Link>
          </Container>
        </div>

        {/* История успеха */}
        <Container className="py-5">
          <h2 className="text-center">Success Stories</h2>
          <Row>
            <Col md={6}>
              <p>
                "Thanks to travellparke, we’ve been able to grow our travel
                agency exponentially. Their platform is easy to use and their
                customer service is unmatched." - Sarah T., Travel Partner
              </p>
            </Col>
            <Col md={6}>
              <p>
                "travellparke has revolutionized the way we book flights and
                hotels. It's the perfect solution for B2B travel." - John D.,
                Corporate Client
              </p>
            </Col>
          </Row>
        </Container>

        {/* Блок с технологией */}
        <Container className="bg-light py-5">
          <h2 className="text-center">Our Technology</h2>
          <Row>
            <Col md={6}>
              <p>
                Our platform is powered by the latest technology, ensuring a
                seamless experience for all our users. We continuously improve
                our systems to meet the evolving needs of the travel industry.
              </p>
            </Col>
            <Col md={6}>
              <img
                src="/images/technology.jpg"
                alt="Technology"
                className="img-fluid rounded"
              />
            </Col>
          </Row>
        </Container>

        {/* Команда */}
        <Container className="py-5">
          <h2 className="text-center">Meet Our Team</h2>
          <Row>
            <Col md={4}>
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>John Doe</Card.Title>
                  <p>CEO & Founder</p>
                  <p>
                    John leads travellparke with a passion for innovation in the
                    travel industry.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>Jane Smith</Card.Title>
                  <p>Chief Technology Officer</p>
                  <p>
                    Jane is responsible for ensuring that our technology stays
                    at the forefront of the industry.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>Mike Johnson</Card.Title>
                  <p>Head of Partnerships</p>
                  <p>
                    Mike manages our relationships with travel suppliers and
                    business partners.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* FAQ */}
        <Container className="py-5">
          <h2 className="text-center">Frequently Asked Questions</h2>
          <Row>
            {[
              [
                "How do I get started with travellparke?",
                "Simply sign up and start connecting with our partners immediately.",
              ],
              [
                "What services does travellparke provide?",
                "We offer flight bookings, hotel reservations, and much more through our platform.",
              ],
              [
                "Can I book flights for my clients?",
                "Yes, you can book flights and hotels for your clients through our system.",
              ],
              [
                "Is there a mobile app available?",
                "Currently, our platform is web-based, but we are working on a mobile app.",
              ],
              [
                "How can I contact support?",
                "Our support team is available 24/7 via chat, email, or phone.",
              ],
              [
                "Are there any hidden fees?",
                "No, we believe in transparency. All fees are clearly outlined.",
              ],
              [
                "Can I customize my travel packages?",
                "Yes, we offer customizable packages for our partners.",
              ],
              [
                "What are the payment options?",
                "We accept multiple payment methods including credit cards and bank transfers.",
              ],
            ].map(([question, answer], idx) => (
              <Col md={6} key={idx}>
                <div className="faq-item">
                  <h5>{question}</h5>
                  <p>{answer}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>

        {/* Отзывы */}
        <Container className="py-5">
          <h2 className="text-center">What Our Clients Say</h2>
          <Carousel>
            {[
              [
                "Sarah T.",
                "Travel Partner",
                "travellparke has made it so easy for us to book travel for our clients. Highly recommend!",
              ],
              [
                "John D.",
                "Corporate Client",
                "A seamless experience from start to finish. Great platform for booking and managing travel.",
              ],
              [
                "Alice M.",
                "Travel Agent",
                "Excellent customer service and an intuitive platform. travellparke has transformed our business.",
              ],
              [
                "Bob W.",
                "Travel Partner",
                "We’ve been able to increase our bookings by 40% since joining travellparke. A game-changer.",
              ],
              [
                "Emily P.",
                "Corporate Client",
                "I love how easy it is to use and how quick their support is. Wouldn’t use any other platform.",
              ],
            ].map(([name, title, feedback], idx) => (
              <Carousel.Item key={idx}>
                <div className="text-center">
                  <h4>{name}</h4>
                  <p>
                    <strong>{title}</strong>
                  </p>
                  <p>{feedback}</p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>

        {/* Специальная платформа */}
        <div className="banner bg-dark text-white text-center py-5">
          <Container>
            <h2>Our Special B2B Platform</h2>
            <p>
              We provide a robust B2B platform to streamline your travel
              business, making operations faster and more efficient.
            </p>
            <Link to="/contact">
              <Button variant="success" size="lg">
                Get Started
              </Button>
            </Link>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;

import React from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const Navbar = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark sticky-top shadow-lg py-3"
      style={{
        background: "linear-gradient(135deg, #1E3A8A 0%, #1D4ED8 100%)", // Градиент синий
      }}
    >
      <div className="container">
        <NavLink
          className="navbar-brand fw-bold fs-2"
          to="/"
          style={{
            color: "#FACC15", // Желтый для логотипа
            textShadow: "2px 2px 8px rgba(0, 0, 0, 0.6)", // Тень
          }}
        >
          travellparke
        </NavLink>
        <button
          className="navbar-toggler border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarContent">
          <ul className="navbar-nav mx-auto text-center">
            {["Home", "Solutions", "About", "Contact"].map((item, idx) => (
              <li className="nav-item" key={idx}>
                <NavLink
                  className="nav-link fs-5 mx-3"
                  to={`/${item.toLowerCase()}`}
                  activeClassName="active"
                  style={{
                    color: "#E5E7EB", // Светлый текст
                    fontWeight: "500",
                    transition: "color 0.3s ease",
                  }}
                  onMouseEnter={(e) => (e.target.style.color = "#FACC15")}
                  onMouseLeave={(e) => (e.target.style.color = "#E5E7EB")}
                >
                  {item}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="btn-group">
            <NavLink
              to="/login"
              className="btn btn-outline-light rounded-pill mx-1"
              style={{
                borderColor: "#FACC15",
                color: "#FACC15",
                transition: "background-color 0.3s ease, color 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#FACC15";
                e.target.style.color = "#1E3A8A";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "transparent";
                e.target.style.color = "#FACC15";
              }}
            >
              Login
            </NavLink>
            <NavLink
              to="/register"
              className="btn btn-warning rounded-pill mx-1"
              style={{ color: "#1E3A8A" }}
            >
              Register
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

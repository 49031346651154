import React from "react";

const Footer = () => {
  return (
    <footer
      className="footer text-white text-center py-5"
      style={{
        background: "linear-gradient(135deg, #1E3A8A 0%, #1D4ED8 100%)",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold text-uppercase">About travellparke</h5>
            <p style={{ color: "#CBD5E1" }}>
              At travellparke, we provide top-notch corporate travel solutions,
              blending innovation and reliability.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold text-uppercase">Explore</h5>
            <ul className="list-unstyled">
              {["Home", "Solutions", "About", "Contact"].map((item, idx) => (
                <li key={idx}>
                  <a
                    href={`/${item.toLowerCase()}`}
                    className="text-decoration-none"
                    style={{
                      color: "#A5B4FC", // Светло-голубой текст
                      transition: "color 0.3s ease",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "#FACC15")}
                    onMouseLeave={(e) => (e.target.style.color = "#A5B4FC")}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold text-uppercase">Connect with Us</h5>
            <div>
              {[
                { icon: "fa-twitter", link: "https://twitter.com/" },
                { icon: "fa-facebook", link: "https://facebook.com/" },
              ].map((social, idx) => (
                <a
                  key={idx}
                  href={social.link}
                  className="fs-4 me-4"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#A5B4FC",
                    transition: "color 0.3s ease, transform 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.color = "#FACC15";
                    e.target.style.transform = "scale(1.1)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "#A5B4FC";
                    e.target.style.transform = "scale(1)";
                  }}
                >
                  <i className={`fa ${social.icon}`}></i>
                </a>
              ))}
            </div>
          </div>
        </div>
        <hr className="my-4" style={{ borderColor: "#475569" }} />
        <p className="mb-0" style={{ color: "#CBD5E1" }}>
          © 2024 travellparke. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Solutions = () => {
  return (
    <div>
      {/* Введение */}
      <section className="intro py-5 text-center bg-light">
        <Container>
          <h1 className="display-4">Our Services</h1>
          <p className="lead">
            We provide comprehensive solutions for travel businesses with
            tailored services in aviation, accommodation, and more. Explore our
            offerings below to learn how we can support your success.
          </p>
        </Container>
      </section>

      {/* Авиа-услуги */}
      <section className="aviation-services py-5">
        <Container>
          <h2 className="text-center mb-4">Aviation Services</h2>
          <Row>
            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Img variant="top" src="/images/aviations.jpg" />
                <Card.Body>
                  <Card.Title>Airline Partnerships</Card.Title>
                  <Card.Text>
                    We have established strong partnerships with major airlines
                    around the globe, providing you with exclusive rates and
                    seamless flight booking experiences. Our platform offers
                    easy access to a wide range of flight options, tailored to
                    the needs of your business.
                  </Card.Text>
                  <Link to="/contact">
                    <Button variant="primary">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Img variant="top" src="/images/aviation2.jpg" />
                <Card.Body>
                  <Card.Title>Flight Booking System</Card.Title>
                  <Card.Text>
                    Our advanced flight booking system is designed to streamline
                    the booking process, offering real-time updates and the
                    ability to manage multiple bookings at once. This tool is
                    integrated with our platform, allowing for smooth
                    communication between travel agents and clients.
                  </Card.Text>
                  <Link to="/contact">
                    <Button variant="primary">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Услуги по отелям */}
      <section className="hotel-services py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4">Hotel Services</h2>
          <Row>
            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Img variant="top" src="/images/hotel1.jpg" />
                <Card.Body>
                  <Card.Title>Global Hotel Network</Card.Title>
                  <Card.Text>
                    Our platform connects you to a vast network of hotels
                    worldwide, offering competitive rates and exceptional
                    services. Whether you're booking for business or leisure, we
                    provide options that meet the highest standards of quality
                    and comfort.
                  </Card.Text>
                  <Link to="/contact">
                    <Button variant="primary">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Img variant="top" src="/images/hotel2.jpg" />
                <Card.Body>
                  <Card.Title>Seamless Hotel Booking</Card.Title>
                  <Card.Text>
                    With our easy-to-use booking system, you can quickly reserve
                    hotel rooms, manage bookings, and ensure the best rates for
                    your clients. Our platform integrates with major hotel
                    chains to provide the most convenient booking experience.
                  </Card.Text>
                  <Link to="/contact">
                    <Button variant="primary">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* B2B сервис */}
      <section className="b2b-services py-5">
        <Container>
          <h2 className="text-center mb-4">B2B Services</h2>
          <Row>
            <Col md={12}>
              <Card className="shadow-sm">
                <Card.Img variant="top" src="/images/b2b.jpg" />
                <Card.Body>
                  <Card.Title>Tailored B2B Solutions</Card.Title>
                  <Card.Text>
                    Our B2B solutions are tailored to the unique needs of your
                    business. Whether you're an agency, tour operator, or
                    corporate client, we offer flexible options to help you
                    grow. Our platform supports bulk bookings, custom pricing,
                    and multi-user access, all designed to maximize efficiency
                    and savings for your business.
                  </Card.Text>
                  <Link to="/contact">
                    <Button variant="primary">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Наша система */}
      <section className="our-system py-5 bg-light">
        <Container>
          <h2 className="text-center mb-4">Our Platform</h2>
          <Row>
            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Img variant="top" src="/images/system1.jpg" />
                <Card.Body>
                  <Card.Title>All-in-One Travel Platform</Card.Title>
                  <Card.Text>
                    Our platform is an all-in-one solution for managing travel
                    and accommodation bookings. From real-time flight and hotel
                    reservations to seamless invoicing and reporting, we offer
                    everything your business needs to stay organized and
                    efficient.
                  </Card.Text>
                  <Link to="/contact">
                    <Button variant="primary">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Img variant="top" src="/images/system2.avif" />
                <Card.Body>
                  <Card.Title>Advanced Reporting Tools</Card.Title>
                  <Card.Text>
                    Our system includes advanced reporting tools, allowing you
                    to track bookings, client preferences, and financial data
                    with ease. This functionality helps you make informed
                    decisions and better manage your business operations.
                  </Card.Text>
                  <Link to="/contact">
                    <Button variant="primary">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Дополнительные услуги */}
      <section className="additional-services py-5">
        <Container>
          <h2 className="text-center mb-4">Additional Services</h2>
          <Row>
            <Col md={4}>
              <Card className="shadow-sm">
                <Card.Img variant="top" src="/images/service1.jpg" />
                <Card.Body>
                  <Card.Title>24/7 Support</Card.Title>
                  <Card.Text>
                    Our dedicated support team is available around the clock to
                    assist with any issues or queries you may have, ensuring a
                    smooth experience for your business.
                  </Card.Text>
                  <Link to="/contact">
                    <Button variant="primary">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="shadow-sm">
                <Card.Img variant="top" src="/images/service2.jpg" />
                <Card.Body>
                  <Card.Title>Customizable Packages</Card.Title>
                  <Card.Text>
                    We offer customizable travel packages that can be tailored
                    to suit the specific needs of your business, ensuring
                    maximum flexibility and value.
                  </Card.Text>
                  <Link to="/contact">
                    <Button variant="primary">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="shadow-sm">
                <Card.Img variant="top" src="/images/service3.jpg" />
                <Card.Body>
                  <Card.Title>Business Travel Management</Card.Title>
                  <Card.Text>
                    Our business travel management service allows you to
                    streamline bookings, approvals, and reporting, saving time
                    and reducing costs for your corporate clients.
                  </Card.Text>
                  <Link to="/contact">
                    <Button variant="primary">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Solutions;

import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Footer, Navbar } from "../components";

const About = () => {
  return (
    <>
      <Navbar />
      <div>
        {/* Введение */}
        <section className="about-intro py-5 text-center bg-light">
          <Container>
            <h1 className="display-4">About Us</h1>
            <p className="lead">
              We are a global leader in travel solutions, dedicated to providing
              tailored services for businesses. Our mission is to make travel
              easy and efficient for B2B partners worldwide. Discover our story
              and learn more about the team behind our success.
            </p>
          </Container>
        </section>

        {/* История компании */}
        <section className="company-history py-5">
          <Container>
            <h2 className="text-center mb-4">Our Journey</h2>
            <Row>
              <Col md={6}>
                <Card className="shadow-sm">
                  <Card.Img variant="top" src="/images/history1.jpg" />
                  <Card.Body>
                    <Card.Title>Our Beginnings</Card.Title>
                    <Card.Text>
                      Founded in 2010, our company started as a small travel
                      agency focused on providing personalized travel
                      experiences for corporate clients. With a strong
                      commitment to innovation and customer satisfaction, we
                      quickly grew into a global service provider, offering a
                      wide range of travel solutions to businesses worldwide.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="shadow-sm">
                  <Card.Img variant="top" src="/images/history2.png" />
                  <Card.Body>
                    <Card.Title>Our Expansion</Card.Title>
                    <Card.Text>
                      Over the years, we've expanded our services to include
                      aviation, hotel booking, and B2B solutions. In 2015, we
                      launched our cutting-edge platform, which allowed us to
                      provide more efficient, streamlined services to our
                      growing client base. Today, we are proud to serve
                      businesses of all sizes and industries around the world.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Таймлайн */}
        <section className="timeline py-5 bg-light">
          <Container>
            <h2 className="text-center mb-4">Timeline</h2>
            <Row>
              <Col md={12}>
                <ul className="timeline-list">
                  <li>
                    <h4>2010: Foundation</h4>
                    <p>
                      Our company was founded with a vision to revolutionize
                      business travel.
                    </p>
                  </li>
                  <li>
                    <h4>2012: First Expansion</h4>
                    <p>
                      We expanded our services to include international flight
                      bookings.
                    </p>
                  </li>
                  <li>
                    <h4>2015: Platform Launch</h4>
                    <p>
                      We launched our innovative platform, streamlining travel
                      management for businesses.
                    </p>
                  </li>
                  <li>
                    <h4>2018: Global Reach</h4>
                    <p>
                      Our network grew, and we began providing services to
                      clients in over 50 countries.
                    </p>
                  </li>
                  <li>
                    <h4>2022: Industry Leadership</h4>
                    <p>
                      We became a global leader in travel solutions, with a
                      strong focus on B2B services.
                    </p>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Наша команда */}
        <section className="team py-5">
          <Container>
            <h2 className="text-center mb-4">Meet Our Team</h2>
            <Row>
              <Col md={4}>
                <Card className="shadow-sm text-center">
                  <Card.Img variant="top" src="/images/team1.webp" />
                  <Card.Body>
                    <Card.Title>John Doe</Card.Title>
                    <Card.Text>CEO & Founder</Card.Text>
                    <p>
                      John founded our company with a vision of simplifying
                      travel for businesses. With over 20 years of experience in
                      the travel industry, he leads the company with passion and
                      dedication.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="shadow-sm text-center">
                  <Card.Img variant="top" src="/images/team2.jpg" />
                  <Card.Body>
                    <Card.Title>Jane Smith</Card.Title>
                    <Card.Text>COO</Card.Text>
                    <p>
                      Jane is responsible for overseeing the daily operations of
                      the company. With her strong background in operations
                      management, she ensures our services are delivered
                      efficiently and effectively.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="shadow-sm text-center">
                  <Card.Img variant="top" src="/images/team3.jpg" />
                  <Card.Body>
                    <Card.Title>Michael Lee</Card.Title>
                    <Card.Text>CTO</Card.Text>
                    <p>
                      Michael leads our technology division, driving innovation
                      and ensuring that our platform stays ahead of the
                      competition. His expertise in software development is key
                      to our success.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Почему мы */}
        <section className="why-us py-5 bg-light">
          <Container>
            <h2 className="text-center mb-4">Why Choose Us?</h2>
            <Row>
              <Col md={6}>
                <Card className="shadow-sm">
                  <Card.Body>
                    <Card.Title>Global Expertise</Card.Title>
                    <Card.Text>
                      With a presence in over 50 countries, we have the
                      expertise and resources to provide top-notch travel
                      solutions for businesses anywhere in the world.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="shadow-sm">
                  <Card.Body>
                    <Card.Title>Innovative Technology</Card.Title>
                    <Card.Text>
                      Our platform is built with the latest technology to ensure
                      fast, seamless, and reliable services for our clients.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Контакт */}
        <section className="contact-us py-5 text-center">
          <Container>
            <h2 className="mb-4">Get In Touch</h2>
            <p>
              We would love to hear from you. Whether you have a question, need
              more information, or want to start a partnership, feel free to
              reach out.
            </p>
            <Link to="/contact">
              <Button variant="primary">Contact Us</Button>
            </Link>
          </Container>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default About;
